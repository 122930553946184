import * as React from 'react'

import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'

import Layout from '../components/Layout'
import Seo from '../components/Seo'

const NotFoundPage = () => (
  <Layout>
    <Seo title="Page not found" />
    <div className="wrapper">
      <div className="upper">
        <h1>Page not Found</h1>
      </div>
    </div>
  </Layout>
)

export default withPrismicUnpublishedPreview(NotFoundPage)
